<template>
    <div class="container-fluid">
        <div class="container head mt-0">
            <div class="title">{{ $t('contactUs.getInTouch') }}</div>
        </div>
        <div class="container mt-0 pt-0">
            <div class="row content">
                <div class="col-6">
                    <div class="head-img bg-gray cursor">
                        <div class="item-title">{{ $t('contactUs.liveChat') }}</div>
                        <div class="item-note">
                            {{ $t('contactUs.chatOnline') }}
                        </div>
                    </div>

                    <div class="item cursor item-panel">
                        <div class="icon-btn icon iconfont">&#xe60d;</div>
                        <img src="https://static.vitaalumnicn.com/static/vsweb/contact-us-1-1.png"
                             alt="">
                    </div>
                    <div class="head-img bg-gray cursor item-panel"
                         @click="$goto('/faqs')"
                         style="margin-top: 20px">
                        <div class="icon-btn icon iconfont">&#xe60d;</div>
                        <div class="item-title">{{ $t('contactUs.faqs') }}</div>
                        <div class="item-note">
                            {{ $t('contactUs.allQuestionsAnswered') }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="right-panel item-panel">
                        <div class="form-panel" v-if="showFormPanel">
                            <div class="form-title">{{ $t('contactUs.contactUs') }}</div>
                            <div class="form-note">
                                {{ $t('contactUs.ifYouWouldLikeToLearn') }}
                            </div>
                            <div class="split-line mt-3"></div>
                            <div class="form-line mt-3">
                                <div class="form-label">{{ $t('contactUs.enquiryType') }}</div>
                                <select v-model="formData.enquiryType" @change="changeEnquiryType($event)">
                                    <option value="1">{{ $t('contactUs.selectAnEnquiryType') }}</option>
                                    <option value="2">{{ $t('contactUs.interestedIn') }}</option>
                                    <option value="3">{{ $t('contactUs.haveAlreadyBooked') }}</option>
                                    <option value="3">{{ $t('contactUs.generalEnquiry') }}</option>
                                </select>
                            </div>
                            <div class="form-line row mt-4">
                                <div class="col-6">
                                    <div class="form-label">{{ $t('contactUs.firstName') }}</div>
                                    <input class="my-input" type="text">
                                </div>
                                <div class="col-6">
                                    <div class="form-label">{{ $t('contactUs.surname') }}</div>
                                    <input class="my-input" type="text">
                                </div>
                            </div>

                            <div class="form-line mt-4">
                                <div class="form-label">{{ $t('contactUs.email') }}</div>
                                <input class="my-input" type="text">
                            </div>

                            <div class="form-line mt-4">
                                <div class="form-label1">{{ $t('contactUs.phone') }}</div>
                                <div class="d-flex align-items-center">
                                    <select class="country" v-model="formData.enquiryType"
                                            @change="changeEnquiryType($event)">
                                        <option value="1">未选择</option>
                                        <option value="2">未选择1</option>
                                        <option value="3">未选择2</option>
                                    </select>
                                    <input class="my-input" type="text">
                                </div>
                            </div>
                            <div class="form-line mt-4">
                                <div class="form-label1">{{ $t('contactUs.location') }}</div>
                                <select v-model="formData.enquiryType" @change="changeEnquiryType($event)">
                                    <option value="1">未选择</option>
                                    <option value="2">未选择1</option>
                                    <option value="3">未选择2</option>
                                </select>
                            </div>

                            <div class="form-line mt-4">
                                <div class="form-label">{{ $t('contactUs.yourMessage') }}</div>
                                <textarea class="my-input" type="text">
								</textarea>
                            </div>


                            <div class="form-line mt-4">
                                <div class="form-label">{{ $t('contactUs.howWouldYouLikeToBeContacted') }}</div>
                                <el-radio-group v-model="formData.channel"
                                                class="d-flex align-items-center justify-content-between">
                                    <el-radio :label="1">{{ $t('contactUs.phone') }}</el-radio>
                                    <el-radio :label="2">{{ $t('contactUs.email') }}</el-radio>
                                    <el-radio :label="3">WhatsApp</el-radio>
                                    <el-radio :label="4">{{ $t('contactUs.weChat') }}</el-radio>
                                </el-radio-group>
                            </div>

                            <div class="form-line mt-4 d-flex">
                                <el-checkbox class="me-2 checked" v-model="formData.checked"></el-checkbox>
                                <div>
                                    {{ $t('contactUs.bySubmitting') }}
                                </div>
                            </div>

                            <div class="submit-btn mt-4">{{ $t('contactUs.sayHello') }}</div>
                        </div>

                        <div v-else @click="showFormPanel = true">
                            <div class="head-img bg-gray cursor">
                                <div class="item-title">{{ $t('contactUs.makeAnEnquiry') }}</div>
                                <div class="item-note">
                                    {{ $t('contactUs.leaveYourDetails') }}
                                </div>
                            </div>
                            <div class="item cursor">
                                <div class="icon-btn icon iconfont">&#xe760;</div>
                                <img
                                    src="https://static.vitaalumnicn.com/static/vsweb/contact-us-1-2.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid m-t-30">
            <img src="https://static.vitaalumnicn.com/static/vsweb/contact-us-2-1.png" alt="">
        </div>

    </div>
</template>

<script>
export default {
    name: "contact-us",
    data() {
        return {
            showFormPanel: false,
            checked: false,
            formData: {
                enquiryType: '1',
                channel: ""
            }
        }
    },
    methods: {
        changeEnquiryType(e) {

        }
    }
}
</script>

<style lang="less" scoped>

.item-panel:hover {

    .icon-btn {
        border: 1px solid #e91a4c;
        background-color: #000;
    }
}

.container-fluid {
    margin-top: 0;
    padding-top: 0;
}

.container-fluid {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.head {
    color: #fff;
    padding: 108px 0;
    position: relative;
    font-family: Trade Gothic LT Std Condensed, sans-serif;

    .title {
        font-size: 80px;
        font-weight: 700;
    }

    .title:before {
        margin-top: -10px;
        width: 90px;
        content: "";
        display: block;
        background-color: #e91a4c;
        position: absolute;
        height: 7px;
    }

    .sub-title {
        margin-top: 10px;
        font-size: 18px;
    }
}

.item {
    position: relative;
    overflow: hidden;
    display: flex;
    color: #fff;
    aspect-ratio: 26 / 15;
    transition: transform 0.6s ease;

    img {
        top: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: auto;
        aspect-ratio: 26 / 15;
        transition: transform 0.5s ease;
    }

}

.head-img {
    position: relative;
    color: white;
    padding: 30px;

    .item-title {
        font-size: 26px;
        font-weight: 700;
        padding-bottom: 10px;
        font-family: Trade Gothic LT Std Condensed, sans-serif;
    }

    .item-title:before {
        margin-top: 45px;
        width: 90px;
        content: "";
        display: block;
        background-color: #e91a4c;
        position: absolute;
        height: 1px;
    }

    .item-note {
        padding-top: 20px;
        font-size: 16px;
    }
}

.item:hover {

    img {
        transform: scale(1.15);
    }
}

.right-panel {
    position: relative;

    .form-panel {
        z-index: 1;
        border: 1px rgba(255, 255, 255, 0.58) solid;
        color: #fff;
        padding: 30px;

        .form-title {
            font-size: 20px;
            font-weight: 700;
        }

        .form-note {
            margin-top: 10px;
            font-size: 14px;
        }

        .form-label {
            font-size: 15px;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .form-label::after {
            content: " *";
            color: #e91a4c;
        }

        .form-label1 {
            font-size: 15px;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .country {
            width: 100px;
            margin-right: 5px;
            border-radius: 0;
        }

        select {
            width: 100%;
            background-color: #000000;
            line-height: 36px;
            height: 36px;
            border-radius: 5px;
            color: #fff;
            padding: 0 10px;
            font-size: 14px;
            border: 1px #999 solid;

            option {
                background-color: #000000;
                line-height: 30px;
            }
        }

        textarea, input {
            background-color: #000000;
            color: #fff;
            line-height: 36px;
            height: 36px;
            width: 100%;
            font-size: 14px;
            border-radius: 0;
            border: 1px #999 solid;
            padding: 0 10px;
        }

        textarea {
            height: 130px;
            padding: 15px;
        }

        .my-input {
            transition: border-color 0.3s ease;
        }

        .my-input:focus {
            border-color: #e91a4c;
            outline: none;
        }

        .submit-btn {
            width: 100%;
            height: 35px;
            line-height: 35px;
            background-color: #e91a4c;
            color: white;
            border-radius: 18px;
            text-align: center;
            font-size: 16px;

        }

        .submit-btn:hover {
            background-color: #2d2d2d;
        }

        .checked {
            /deep/ .el-checkbox__inner {
                display: inline-block;
                position: relative;
                border: 1px solid #bdb2b3;
                border-radius: 2px;
                box-sizing: border-box;
                width: 28px;
                height: 28px;
                background-color: #2b2b2b;
                z-index: 1;
                transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
            }

            /deep/ .el-checkbox__inner::after {
                box-sizing: content-box;
                content: "";
                border: 3px solid #FFF;
                border-left: 0;
                border-top: 0;
                height: 15px;
                left: 10px;
                position: absolute;
                top: 1px;
                //-webkit-transform: rotate(45deg) scaleY(0);
                //transform: rotate(45deg) scaleY(0);
                width: 6px;
                transition: -webkit-transform .15s ease-in .05s;
                transition: transform .15s ease-in .05s;
                transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
                transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
                -webkit-transform-origin: center;
                transform-origin: center;
            }
        }
    }
}


.icon-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #e91a4c;
    color: white;
    font-size: 24px;
    font-weight: bold;
    z-index: 1;
    right: 0;
    bottom: 0;
}

.bg-gray {
    padding: 30px;
    background-color: #202022;
}
</style>
